import React, { useMemo } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { ForwardedRef } from 'react';
import { FieldAndValue } from '../../../infrastructure/interface/components/FieldAndValue';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import {
  getRoleFromCodeCharacter, UserRoleCodeCharacter
} from '../../authentication/UserRole';
import { GetUserResponse } from './EditUser';
import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';
import { SingleSelectField } from '../../../infrastructure/forms/fields/SingleSelectField';
import { LockedOutField } from './LockedOutField';
import { spacing16 } from '../../../styling/design/spacing';
import styled from 'styled-components/macro';
import { usePutJson } from '../../../infrastructure/api/usePutJson';
import { Validator } from 'fluentvalidation-ts';
import { TranslateFunction } from '../../../internationalisation/types/InternationalisationContextValue';

type Props = {
  initialUserDetails: GetUserResponse;
  onEditUserError: (error: string) => void;
  onEditUserSuccess: () => void;
};

const EditNonAdminUserFormComponent = (
  props: Props,
  forwardedRef: ForwardedRef<FormikProps<EditNonAdminUserFormModel>>
) => {
  const { translate } = useInternationalisation();

  const editNonAdminUserRequest = usePutJson<EditNonAdminUserCommand, {}>(
    '/api/users/EditNonAdminUser'
  );

  const initialFormValues = mapGetUserResponseToFormModel(props.initialUserDetails);

  const validator = useMemo(() => new EditNonAdminUserFormValidator(translate, props.initialUserDetails.enabledWebRole), [translate, props]);

  const onSubmit = (
    formValues: EditNonAdminUserFormModel,
    formikHelpers: FormikHelpers<EditNonAdminUserFormModel>
  ) => {
    editNonAdminUserRequest.makeRequest({
      requestBody: {
        userId: props.initialUserDetails.userId,
        ...formValues
      },
      onSuccess: () => {
        formikHelpers.setSubmitting(false);
        props.onEditUserSuccess();
      },
      onFailure: (error: string) => {
        formikHelpers.setSubmitting(false);
        props.onEditUserError(error);
      }
    });
  };

  const roleDropdownOptions: SelectOptions<UserRoleCodeCharacter> = props.initialUserDetails.enabledWebRole.map(
    (role) => ({
      label: getRoleFromCodeCharacter(role),
      value: role
    })
  );

  return (
    <Formik<EditNonAdminUserFormModel>
      initialValues={initialFormValues}
      onSubmit={onSubmit}
      innerRef={forwardedRef}
      validate={validator.validate}
    >
      <Form>
        <FormFieldsContainer>
          <FieldAndValue
            type="text"
            value={props.initialUserDetails.username}
            fieldLabel={translate('pages.editUser.fieldLabels.username')}
          />
          <FieldAndValue
            type="text"
            value={props.initialUserDetails.counterpartName}
            fieldLabel={translate('pages.editUser.fieldLabels.counterpart')}
          />
          <SingleSelectField
            fieldName="roleCodeCharacter"
            options={roleDropdownOptions}
            label={translate('pages.editUser.fieldLabels.role')}
          />
          <LockedOutField fieldName="isLockedOut" />
        </FormFieldsContainer>
      </Form>
    </Formik>
  );
};

export const EditNonAdminUserForm = React.forwardRef<FormikProps<EditNonAdminUserFormModel>, Props>(
  EditNonAdminUserFormComponent
);

const mapGetUserResponseToFormModel = (response: GetUserResponse): EditNonAdminUserFormModel => ({
  roleCodeCharacter: response.roleCodeCharacter,
  isLockedOut: response.isLockedOut
});

const FormFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing16};
`;

type EditNonAdminUserFormModel = {
  roleCodeCharacter: UserRoleCodeCharacter;
  isLockedOut: boolean;
};

export type EditNonAdminUserCommand = {
  userId: string;
  isLockedOut: boolean;
  roleCodeCharacter: UserRoleCodeCharacter;
};

class EditNonAdminUserFormValidator extends Validator<EditNonAdminUserFormModel> {
  constructor(translate: TranslateFunction, enabledWebRole: Array<UserRoleCodeCharacter>) {
    super();
    debugger;
    this.ruleFor('roleCodeCharacter')
      .must((value) => enabledWebRole.includes(value))
      .withMessage(translate('validation.validRole'));
    
  }
}