import React from 'react';
import { baseChartOptions, BaseChartProps, Chart } from './Chart';
import {
  AxisTypeValue,
  PointOptionsObject,
  SeriesOptions,
  XAxisOptions,
  YAxisOptions,
} from 'highcharts';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { toIsoDatestampFromDate } from '../../helpers/dateTimeHelpers';

export type MultiLineGraphDataPoint = {
  x: number | Date;
  y: number;
};

type Props = BaseChartProps & {
  xAxisType?: AxisTypeValue;
  companyID?: number | null;
  dataPoints: Array<MultiLineGraphDataPoint>;
  dataPoints1: Array<MultiLineGraphDataPoint>;
  dataPoints2: Array<MultiLineGraphDataPoint>;
  dataPointsName: string;
  dataPoints1Name: string;
  dataPoints2Name: string;
  xAxisOptions?: XAxisOptions;
  yAxisOptions?: YAxisOptions;
  seriesOption?: SeriesOptions;
  fundComponent?: boolean;
};

export const MultiLineGraph = ({
  xAxisType = 'linear',
  companyID,
  dataPoints,
  dataPoints1,
  dataPoints2,
  dataPointsName,
  dataPoints1Name,
  dataPoints2Name,
  xAxisOptions,
  yAxisOptions,
  tooltipOptions,
  seriesOption,
  fundComponent,
  ...baseChartProps
}: Props) => {
  const { formatNumber, formatDate } = useInternationalisation();

  let Rotation = 0;
  let datePoints;

  if (!fundComponent) {
    if (dataPoints.length >= 56) {
      datePoints = dataPoints
        .filter((num, index) => {
          if (index % 14 === 0) {
            return num;
          }
        })
        .map(mapLineGraphDataPointToPointOptions)
        .map((value) => value.x as number);
    } else {
      datePoints = dataPoints
        .map(mapLineGraphDataPointToPointOptions)
        .map((value) => value.x as number);
    }
    if (datePoints.length > 10) Rotation = -45;
  }

  const showLegendBenchmark2 = dataPoints2.length !== 0;
  const showLegendBenchmark1 = dataPoints1.length !== 0;
  const showLegendPerformance = dataPoints.length !== 0;
  const chartOptions: Highcharts.Options = {
    ...baseChartOptions(baseChartProps),
    plotOptions: {
      series: {
        cursor: 'pointer',
        marker: {
          lineWidth: 1,
        },
        events: {
          click: function (event) {
            if (!fundComponent) {
              const runDate = toIsoDatestampFromDate(new Date(this.data[this.data.length - 1].x));
              const priorDate = toIsoDatestampFromDate(new Date(this.data[0].x));
              const reprotUrl =
                '/reports/10?autoRun=true&@company_id=' +
                companyID +
                '&@prior_date=' +
                priorDate +
                '&@run_date=' +
                runDate;
              window.open(reprotUrl, '_self');
            }
          },
        },
      },
    },
    series: [
      {
        type: 'line',
        name: dataPointsName,
        data: dataPoints.map(mapLineGraphDataPointToPointOptions),
        tooltip: tooltipOptions || {
          headerFormat: '',
          pointFormatter() {
            const formattedX =
              xAxisType === 'datetime'
                ? formatDate(toIsoDatestampFromDate(new Date(this.x)))
                : this.x;
            const formattedY = this.y === undefined ? '' : formatNumber(this.y);
            return `<b>${formattedX}</b><br/>${formattedY}`;
          },
        },
        showInLegend: showLegendPerformance,
      },
      {
        type: 'line',
        name: dataPoints1Name,
        data: dataPoints1.map(mapLineGraphDataPointToPointOptions),
        tooltip: tooltipOptions || {
          pointFormatter() {
            const formattedX =
              xAxisType === 'datetime'
                ? formatDate(toIsoDatestampFromDate(new Date(this.x)))
                : this.x;
            const formattedY = this.y === undefined ? '' : formatNumber(this.y);
            return `<b>${formattedX}</b><br/>${formattedY}`;
          },
        },
        showInLegend: showLegendBenchmark1,
      },

      {
        type: 'line',
        name: dataPoints2Name,
        data: dataPoints2.map(mapLineGraphDataPointToPointOptions),
        tooltip: tooltipOptions || {
          pointFormatter() {
            const formattedX =
              xAxisType === 'datetime'
                ? formatDate(toIsoDatestampFromDate(new Date(this.x)))
                : this.x;
            const formattedY = this.y === undefined ? '' : formatNumber(this.y);
            return `<b>${formattedX}</b><br/>${formattedY}`;
          },
        },
        showInLegend: showLegendBenchmark2,
      },
    ],

    xAxis: {
      type: xAxisType,
      ...xAxisOptions,
      labels: {
        formatter() {
          return formatDate(toIsoDatestampFromDate(new Date(this.value)));
        },
        rotation: Rotation,
      },
      tickPositions: datePoints,
    },
    yAxis: {
      title: {
        text: undefined,
      },
      ...yAxisOptions,
    },
    legend: {
      enabled: true,
    },
  };

  return (
    <Chart data-testid={baseChartProps['data-testid'] ?? 'line-graph'} options={chartOptions} />
  );
};

const mapLineGraphDataPointToPointOptions = (
  point: MultiLineGraphDataPoint
): PointOptionsObject => {
  if (typeof point.x == 'number') {
    return point as PointOptionsObject;
  } else {
    return {
      x: point.x.getTime(),
      y: point.y,
    };
  }
};
