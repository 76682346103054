import React from 'react';
import { BaseSelectProps } from '../../interface/forms/BaseSelect';
import {
  applyCustomStylesToDefaults,
  BaseSelectStylesConfigFactory,
  defaultSelectBorder,
  defaultSelectBorderWidthAndType,
  defaultSelectDisabledBorder,
  defaultSelectFocusBorder,
  defaultSelectHighlightBorder,
} from '../../interface/forms/baseSelectStyles';
import {
  getCustomSingleSelectStyles,
  SingleSelect,
  SingleSelectCustomStyleOptions,
  SingleSelectStylesConfig,
  SingleSelectStylesConfigFactory,
} from '../../interface/forms/SingleSelect';
import { FieldValidity } from '../common/FieldValidity';
import { borderColours } from '../../../styling/design/colours';
import { FormField } from '../common/FormField';

export const singleSelectFieldTestId = (fieldName: string) => `single-select-field-${fieldName}`;

export type BaseSelectFieldProps<TValue extends unknown, IsMulti extends boolean> = Omit<
  BaseSelectProps<TValue, IsMulti>,
  'onBlur' | 'customStyles' | 'name'
> & {
  fieldName: string;
  label?: string;
  helpText?: string;
  customStyles?: (fieldValidity: FieldValidity) => BaseSelectStylesConfigFactory<TValue, IsMulti>;
};

type Props<TValue extends unknown> = BaseSelectFieldProps<TValue, false>;

export const SingleSelectField = <TValue extends unknown>(props: Props<TValue>) => {
  const { fieldName, label, helpText, customStyles, ...selectProps } = props;

  return (
    <FormField<TValue> fieldName={fieldName} label={label} helpText={helpText}>
      {({ field, fieldValidity, formikContext }) => {
        const onChange = (newValue: TValue | null) => {
          formikContext.setFieldValue(field.name, newValue);
        };

        const onBlur = () => {
          formikContext.setFieldTouched(field.name);
        };

        return (
          <SingleSelect<TValue>
            {...selectProps}
            data-testid={props['data-testid'] ?? singleSelectFieldTestId(fieldName)}
            value={field.value}
            onChange={onChange}
            onBlur={onBlur}
            name={field.name}
            disabled={props.disabled || formikContext.isSubmitting}
            customStyles={
              customStyles != null
                ? customStyles(fieldValidity)
                : defaultSingleSelectFieldStyles<TValue>(fieldValidity)
            }
          />
        );
      }}
    </FormField>
  );
};

export const defaultSingleSelectFieldStyles = <TValue extends unknown>(
  fieldValidity: FieldValidity
): SingleSelectStylesConfigFactory<TValue> => {
  return getCustomSingleSelectStyles<TValue>({
    control: (state, highlight) => {
      const borderStyle = getSelectFieldBorderStyle(
        state.isDisabled,
        state.isFocused,
        fieldValidity,
        highlight
      );

      return {
        border: borderStyle,
      };
    },
  });
};

export const getSelectFieldBorderStyle = (
  isDisabled: boolean,
  isFocused: boolean,
  fieldValidity: FieldValidity,
  highlight?: boolean
): string => {
  return isDisabled
    ? defaultSelectDisabledBorder
    : isFocused
    ? defaultSelectFocusBorder
    : fieldValidity === 'Valid'
    ? `${defaultSelectBorderWidthAndType} ${borderColours.positive}`
    : fieldValidity === 'Invalid'
    ? `${defaultSelectBorderWidthAndType} ${borderColours.negative}`
    : highlight
    ? defaultSelectHighlightBorder
    : defaultSelectBorder;
};

export const getCustomSingleSelectFieldStyles =
  <TValue extends unknown>(customStyles: SingleSelectCustomStyleOptions<TValue>) =>
  (fieldValidity: FieldValidity): SingleSelectStylesConfigFactory<TValue> =>
  (highlight?: boolean): SingleSelectStylesConfig<TValue> =>
    applyCustomStylesToDefaults(
      customStyles,
      defaultSingleSelectFieldStyles<TValue>(fieldValidity)(false, highlight),
      highlight
    );
