import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  PopoverMenu,
  PopoverMenuContainer,
  PopoverMenuDivider,
  PopoverMenuItem,
} from '../../../infrastructure/interface/components/PopoverMenu';
import { IfUserHasRole } from '../../../features/authentication/UserRoles';
import { Link, useNavigate } from 'react-router-dom';
import {
  BuildingColumnsIcon,
  CogIcon,
  KeyIcon,
  MoneyWithArrowsIcon,
  SignOutIcon,
  UserCircleSolidIcon,
  UserSlashIcon,
} from '../../../icons/icons';
import styled from 'styled-components/macro';
import { spacing12, spacing16, spacing24, spacing32, spacing8 } from '../../design/spacing';
import { fontSizeCss, fontWeightBold } from '../../design/fonts';
import { colourBlack, colourGrey08, textColours } from '../../design/colours';
import { AuthenticationContext } from '../../../features/authentication/AuthenticationContext';
import { useOnClickOutside } from '../../../infrastructure/hooks/useOnClickOutside';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { sidebarWidth } from './SidebarStyles';
import { investorUserRoles } from '../../../features/authentication/UserRole';
import { useOnUnmount } from '../../../infrastructure/hooks/useOnUnmount';
import { UserDetails } from '../../../features/authentication/UserDetails';

type Props = {
  onRequestSidebarClose: () => void;
  onManagerWebsiteUrlChanged: (url: string | null) => void;
};

export const UserMenu = ({ onRequestSidebarClose, onManagerWebsiteUrlChanged }: Props) => {
  const { translate, formatDateTime } = useInternationalisation();

  const authenticationContext = useContext(AuthenticationContext);
  const navigate = useNavigate();

  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const logOut = () => {
    setIsLoggingOut(true);

    authenticationContext.logOut({
      onSuccess: () => {
        setIsLoggingOut(false);
        navigate('/login');
      },
      onFailure: () => setIsLoggingOut(false),
    });
  };

  useOnUnmount(() => {
    setIsLoggingOut(false); // prevent state change after unmount.
  });

  const [isEndingImpersonation, setIsEndingImpersonation] = useState(false);

  const endImpersonation = () => {
    setIsEndingImpersonation(true);

    authenticationContext.endImpersonation({
      onSuccess: () => {
        setIsEndingImpersonation(false);
        authenticationContext.refresh();
        navigate('/impersonate-user');
      },
      onFailure: () => setIsEndingImpersonation(false),
    });
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const closeMenu = () => setIsMenuOpen(false);

  const menuRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(menuRef, () => closeMenu());

  const closeMenuAndRequestSidebarClose = () => {
    closeMenu();
    onRequestSidebarClose();
  };

  const user: UserDetails = authenticationContext.getUser();
  const userDetails = `${user.name} (${user.role})`;

  const [url, setMangerWebsiteUrl] = useState<string | null>(null);
  useEffect(() => { 
    handleManagerWebsiteUrlChange(user.managerWebsiteUrl)
  }, [url]);

  const handleManagerWebsiteUrlChange = (url: string | null) => {
    setMangerWebsiteUrl(url);
    onManagerWebsiteUrlChanged (url);
  };  

  return (
    <PopoverMenuContainer ref={menuRef}>
      <Username onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <UserCircleIcon />
        {user.isImpersonated
          ? translate('sidebar.userMenu.viewingAsUser', { userDetails })
          : userDetails}
        <br/>
        {user.boName && "("+user.boName+")"}
      </Username>
      <UserPopoverMenu isMenuOpen={isMenuOpen} verticalPlacement="top">
        <LastLogin>
          Last login: {user.lastLoginDate ? formatDateTime(user.lastLoginDate) : 'N/A'}
        </LastLogin>
        <PopoverMenuDivider />
        <IfUserHasRole userRole={['Investor', 'Consolidated Investor', 'Advisor']}>
          {user.permissions.canViewOwnInvestorDetails ? (
            <PopoverMenuItem
              as={Link}
              to="/investor-details"
              onClick={closeMenuAndRequestSidebarClose}
            >
              <CogIcon />
              {translate('sidebar.userMenu.accountSettings')}
            </PopoverMenuItem>
          ) : null}
        </IfUserHasRole>
        <IfUserHasRole userRole={investorUserRoles}>
          {user.permissions.canViewBankingDetails ? (
            <PopoverMenuItem
              as={Link}
              to="/banking-details"
              onClick={closeMenuAndRequestSidebarClose}
            >
              <BuildingColumnsIcon />
              {translate('sidebar.userMenu.bankingDetails')}
            </PopoverMenuItem>
          ) : null}
        </IfUserHasRole>
        <IfUserHasRole userRole={['Investor', 'Consolidated Investor', 'Advisor']}>
          {user.permissions.canViewDistributionReinvestments ? (
            <PopoverMenuItem
              as={Link}
              to="/distribution-reinvestments"
              onClick={closeMenuAndRequestSidebarClose}
            >
              <MoneyWithArrowsIcon />
              {translate('sidebar.userMenu.distributionReinvestments')}
            </PopoverMenuItem>
          ) : null}
        </IfUserHasRole>
        <PopoverMenuItem
          as={Link}
          to={`/forgot-password`}
          onClick={closeMenuAndRequestSidebarClose}
        >
          <KeyIcon />
          {translate('sidebar.userMenu.resetPassword')}
        </PopoverMenuItem>
        <PopoverMenuItem onClick={!isLoggingOut ? logOut : () => {}}>
          <SignOutIcon />
          {isLoggingOut
            ? translate('sidebar.userMenu.loggingOut')
            : translate('sidebar.userMenu.logOut')}
        </PopoverMenuItem>
        {user.isImpersonated && (
          <PopoverMenuItem onClick={!isEndingImpersonation ? endImpersonation : () => {}}>
            <UserSlashIcon />
            {isEndingImpersonation
              ? translate('sidebar.userMenu.endingImpersonation')
              : translate('sidebar.userMenu.endImpersonation')}
          </PopoverMenuItem>
        )}
      </UserPopoverMenu>
    </PopoverMenuContainer>
  );
};

const UserPopoverMenu = styled(PopoverMenu)<{ isMenuOpen: boolean }>`
  display: ${(props) => (props.isMenuOpen ? 'block' : 'none')};
  width: ${sidebarWidth * 0.8}px;
`;

const Username = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  padding: 0 ${spacing32};
  font-weight: ${fontWeightBold};
  color: ${colourGrey08};
  cursor: pointer;
  transition: color 0.25s ease;
  user-select: none;

  &:hover,
  &:focus {
    color: ${colourBlack};
  }
`;

const UserCircleIcon = styled(UserCircleSolidIcon)`
  height: ${spacing24};
  min-height: ${spacing24};
  width: ${spacing24};
  min-width: ${spacing24};
  margin-right: ${spacing16};
  position: relative;
`;

const LastLogin = styled.div`
  ${fontSizeCss('xsmall')};
  text-align: center;
  padding: ${spacing8} ${spacing12};
  color: ${textColours.notice};
`;
