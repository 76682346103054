import React, { useEffect, useMemo } from 'react';
import {
  dealingTransactionType,
  DealingTransactionTypeCode,
} from '../../../metadata/transactions/DealingTransactionType';
import { RadioButtonGroupOption } from '../../../../infrastructure/interface/buttons/RadioButtonGroup';
import styled from 'styled-components/macro';
import { spacing16 } from '../../../../styling/design/spacing';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import {
  transactionLockCode,
  TransactionLockCode,
} from '../../../metadata/transactions/TransactionLock';
import { RadioButtonGroupField } from '../../../../infrastructure/forms/fields/RadioButtonGroupField';
import { useField } from 'formik';
import {
  NumberInputField,
  NumberInputFieldProps,
} from '../../../../infrastructure/forms/fields/NumberInputField';
import { useFormikContext } from 'formik';
import { PlaceTransactionFormModel } from '../PlaceTransaction';
import {
  CompanyEqualizationTypeCode,
  companyEqualizationTypes, DbTransactionTypeCode, dbTransactionTypes
} from '../useGetCompanyTransacitonDetails';
import { isEmpty } from 'lodash';

type Props = {
  companyEqualizationType: CompanyEqualizationTypeCode | null;
  dbTransactionType: DbTransactionTypeCode | null;
  transactionType: DealingTransactionTypeCode;
  shareDecimals?: number | null;
  amountCurrencyCode?: string | null;
};

export const PlaceTransactionAmountOrSharesField = ({
  companyEqualizationType,
  dbTransactionType,
  transactionType,
  shareDecimals,
  amountCurrencyCode,
}: Props) => {
  const { translate } = useInternationalisation();
  const [lockField] = useField<TransactionLockCode>('transactionLock');

  const { setFieldValue, touched } = useFormikContext<PlaceTransactionFormModel>();

  const amountOrSharesOptions: Array<RadioButtonGroupOption<TransactionLockCode>> = [
    {
      value: transactionLockCode.Amount,
      text: translate('pages.placeTransaction.labels.amountOrSharesToggle.amount'),
    },
    {
      value: transactionLockCode.Shares,
      text: translate('pages.placeTransaction.labels.amountOrSharesToggle.shares'),
    },
  ];

  useEffect(() => {
    if (!isEmpty(touched)) {
      setFieldValue('amountOrShares', null);
    }
  }, [lockField.value]); // eslint-disable-line react-hooks/exhaustive-deps

  const numberFormatProps = useMemo((): Partial<NumberInputFieldProps> => {
    let formatProps: Partial<NumberInputFieldProps> = {};

    if (lockField.value === transactionLockCode.Shares && shareDecimals != null) {
      formatProps.decimalPlaces = shareDecimals;
    } else if (lockField.value === transactionLockCode.Amount && amountCurrencyCode != null) {
      formatProps.currencyCode = amountCurrencyCode;
    }

    return formatProps;
  }, [lockField.value, shareDecimals, amountCurrencyCode]);

  const amountOrSharesValidOptions = (dbTransactionType === dbTransactionTypes.allIssueAmountsOnly 
    || dbTransactionType === dbTransactionTypes.allExceptSwitchIssueAmountsOnly) && transactionType === dealingTransactionType.Issue
    ? [amountOrSharesOptions[0]] : amountOrSharesOptions;

  return shouldShowAmountOrSharesField(companyEqualizationType, transactionType) ? (
    <AmountOrSharesFieldsContainer>
      <AmountOrSharesNumberInputField
        data-testid={placeTransactionAmountOrSharesInputTestId}
        fieldName="amountOrShares"
        label={
          lockField.value === transactionLockCode.Amount
            ? translate('pages.placeTransaction.labels.amount', {
                currencyCode: amountCurrencyCode,
              })
            : translate('pages.placeTransaction.labels.shares')
        }
        {...numberFormatProps}
      />
      {companyEqualizationType !== companyEqualizationTypes.limitedPartnership && (
        <RadioButtonGroupField
          fieldName="transactionLock"
          options={amountOrSharesValidOptions}
          label="&nbsp;"
        />
      )}
    </AmountOrSharesFieldsContainer>
  ) : null;
};

const AmountOrSharesFieldsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const AmountOrSharesNumberInputField = styled(NumberInputField)`
  flex-grow: 1;
  margin-right: ${spacing16};
`;
// switch full shold not show Amount for both UnitisedFunds and LimitedPartnershipFunds
export const shouldShowAmountOrSharesField = (
  companyEqualizationType: CompanyEqualizationTypeCode | null,
  transactionType: DealingTransactionTypeCode
) => {
  const validTypesForUnitisedFunds: Array<DealingTransactionTypeCode> = [
    dealingTransactionType.Issue,
    dealingTransactionType.Redeem,
    dealingTransactionType.Switch,
  ];

  const validTypesForLimitedPartnershipFunds: Array<DealingTransactionTypeCode> = [
    dealingTransactionType.Issue,
    dealingTransactionType.Redeem,
  ];

  return (
    (companyEqualizationType === companyEqualizationTypes.limitedPartnership &&
      validTypesForLimitedPartnershipFunds.includes(transactionType)) ||
    (companyEqualizationType !== companyEqualizationTypes.limitedPartnership &&
      validTypesForUnitisedFunds.includes(transactionType))
  );
};

export const placeTransactionAmountOrSharesInputTestId = 'place-transaction-amount-or-shares-input';
