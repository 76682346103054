import { FormikProps } from 'formik';
import { useContext, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components/macro';
import { ApiRequestStateWrapper } from '../../../infrastructure/api/ApiRequestStateWrapper';
import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { useOnMount } from '../../../infrastructure/hooks/useOnMount';
import { useWindowTitle } from '../../../infrastructure/hooks/useWindowTitle';
import { ButtonRow } from '../../../infrastructure/interface/buttons/ButtonRow';
import { SecondaryButton } from '../../../infrastructure/interface/buttons/SecondaryButton';
import { WaitingButton } from '../../../infrastructure/interface/buttons/WaitingButton';
import { Alert } from '../../../infrastructure/interface/components/Alert';
import { BackButton } from '../../../infrastructure/interface/components/BackButton';
import { Header1 } from '../../../infrastructure/interface/components/Headers';
import { NoMobileContentAlert } from '../../../infrastructure/interface/components/NoMobileContentAlert';
import { Panel } from '../../../infrastructure/interface/components/Panel';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { spacing32 } from '../../../styling/design/spacing';
import { DesktopOnly } from '../../../styling/layout/DesktopOnly';
import { MobileOnly } from '../../../styling/layout/MobileOnly';
import { CentredPaddedPage, PaddedPage } from '../../../styling/layout/PaddedPage';
import {
  isAdminOrViewAsRoleCodeCharacter,
  UserRoleCodeCharacter,
} from '../../authentication/UserRole';
import { RequiresUserRole } from '../../authentication/UserRoles';
import { DeleteUserButton } from '../DeleteUserButton';
import { EditAdminUserForm } from './EditAdminUserForm';
import { EditNonAdminUserForm } from './EditNonAdminUserForm';
import { ResetMfaKeyButton } from './ResetMfaKeyButton';
import { ResetPasswordButton } from './ResetPasswordButton';
import { AuthenticationContext } from '../../authentication/AuthenticationContext';

export const EditUser = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.editUser.title'));

  return (
    <RequiresUserRole userRole="Administrator">
      <MobileOnly>
        <MobileEditUserComponent />
      </MobileOnly>
      <DesktopOnly>
        <DesktopEditUserComponentApiRequestStateWrapper />
      </DesktopOnly>
    </RequiresUserRole>
  );
};

const MobileEditUserComponent = () => {
  const { translate } = useInternationalisation();

  return (
    <PaddedPage>
      <Header1>{translate('pages.editUser.header')}</Header1>
      <NoMobileContentAlert header={translate('pages.editUser.mobileAlertHeader')} />
    </PaddedPage>
  );
};

const DesktopEditUserComponentApiRequestStateWrapper = () => {
  const { userId } = useParams<UserIdUrlParam>();
  const getUserRequest = useGetJson<GetUserQuery, GetUserResponse>('/api/users/GetUser');

  const makeRequest = () => {
    if (userId != null) {
      getUserRequest.makeRequest({
        queryParameters: {
          userId,
        },
      });
    }
  };

  useOnMount(() => {
    makeRequest();
  });

  return (
    <ApiRequestStateWrapper apiRequestState={getUserRequest.state} retry={makeRequest}>
      {(response) => <DesktopEditUserComponent initialUserDetails={response} />}
    </ApiRequestStateWrapper>
  );
};

type DesktopEditUserComponentProps = {
  initialUserDetails: GetUserResponse;
};

const DesktopEditUserComponent = ({ initialUserDetails }: DesktopEditUserComponentProps) => {
  const { translate } = useInternationalisation();
  const navigate = useNavigate();
  const { getUser } = useContext(AuthenticationContext);

  const formRef = useRef<FormikProps<any> | null>(null);
  const [editUserError, setEditUserError] = useState<string | null>(null);
  const [userEditedSuccessfully, setUserEditedSuccessfully] = useState(false);

  const onEditUserSuccess = () => setUserEditedSuccessfully(true);

  const onSaveButtonClick = () => {
    setEditUserError(null);
    setUserEditedSuccessfully(false);
    formRef.current?.submitForm();
  };

  return (
    <CentredPaddedPage>
      <Header1>{translate('pages.editUser.header')}</Header1>
      <HeaderActionsContainer>
        <BackButton />
        <ActionButtonsContainer>
          <ResetPasswordButton
            username={initialUserDetails.username}
            emailAddress={initialUserDetails.emailAddress?.split(';')[0]}
          />
          <ResetMfaKeyButton userId={initialUserDetails.userId} />
          {initialUserDetails.username !== getUser().name &&
            initialUserDetails.username.toLowerCase() !== 'admin' && (
              <DeleteUserButton
                userId={initialUserDetails.userId}
                onUserDeleted={() => navigate('/users')}
              />
            )}
        </ActionButtonsContainer>
      </HeaderActionsContainer>
      <Panel>
        {isAdminOrViewAsRoleCodeCharacter(initialUserDetails.roleCodeCharacter) ? (
          <EditAdminUserForm
            initialUserDetails={initialUserDetails}
            onEditUserError={setEditUserError}
            onEditUserSuccess={onEditUserSuccess}
            ref={formRef}
          />
        ) : (
          <EditNonAdminUserForm
            initialUserDetails={initialUserDetails}
            onEditUserError={setEditUserError}
            onEditUserSuccess={onEditUserSuccess}
            ref={formRef}
          />
        )}
        {editUserError && (
          <Alert alertType="negative" withMarginTop={true}>
            {editUserError}
          </Alert>
        )}
        {userEditedSuccessfully && (
          <Alert alertType="positive" withMarginTop={true}>
            {translate('pages.editUser.editSuccessful')}
          </Alert>
        )}
      </Panel>
      <ButtonRow withMarginTop={true} rightAligned={true}>
        <SecondaryButton onClick={() => navigate('/users')}>
          {translate('actionButtons.cancel')}
        </SecondaryButton>
        <WaitingButton
          waitingText={translate('actionButtons.saving')}
          isWaiting={formRef.current?.isSubmitting ?? false}
          disabled={formRef.current?.isSubmitting ?? false}
          onClick={onSaveButtonClick}
        >
          {translate('actionButtons.save')}
        </WaitingButton>
      </ButtonRow>
    </CentredPaddedPage>
  );
};

const HeaderActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing32};
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing32};
`;

type GetUserQuery = {
  userId: string;
};

export type GetUserResponse = {
  userId: string;
  username: string;
  roleCodeCharacter: UserRoleCodeCharacter;
  counterpartName: string;
  emailAddress: string;
  mobileNumber: string;
  accessGroupId: number;
  isLockedOut: boolean;
  enabledWebRole: Array<UserRoleCodeCharacter>;
};

export const userIdUrlParamName = 'userId';
type UserIdUrlParam = typeof userIdUrlParamName;
