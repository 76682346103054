import { useGetJson } from '../../../infrastructure/api/useGetJson';

export const useGetCompanyTransactionDetails = () =>
  useGetJson<GetCompanyTransactionDetailsQuery, GetCompanyTransactionDetailsResponse>(
    '/api/transactions/GetCompanyTransactionDetails'
  );

type GetCompanyTransactionDetailsQuery = {
  companyId: number;
};

export const dbTransactionTypes = {
  all: 'A',
  allIssueAmountsOnly: '1',
  allExceptSwitch: '2',
  allExceptSwitchIssueAmountsOnly: '3',
} as const;

export const companyEqualizationTypes = {
  multiSeries: 'M',
  noEqualisationAbsolute: 'N',
  equalisationAbsoluteBasis: '1',
  equalisationPerShareBasis: '2',
  noEqualisationPerShareBasis: 'P',
  limitedPartnership: 'L',
} as const;

type TransactionType = keyof typeof dbTransactionTypes;

export type DbTransactionTypeCode = typeof dbTransactionTypes[TransactionType];

type CompanyEqualizationType = keyof typeof companyEqualizationTypes;

export type CompanyEqualizationTypeCode = typeof companyEqualizationTypes[CompanyEqualizationType];

export type GetCompanyTransactionDetailsResponse = {
  transactionTypeCode: DbTransactionTypeCode;
  shareDecimals: number;
  canEnterTransactionReference: boolean;
  equalizationTypeCode: CompanyEqualizationTypeCode;
  currencyCode: string;
};
