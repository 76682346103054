import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { IsoDatestamp } from '../../../helpers/dateTimeHelpers';
import { isNullUndefinedOrBlank } from '../../../helpers/stringHelpers';
import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { BarChart } from '../../../infrastructure/charts/BarChart';
import { ChartDataPoint } from '../../../infrastructure/charts/Chart';
import { FieldLabel } from '../../../infrastructure/forms/common/FieldLabel';
import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';
import {
  getCustomSingleSelectStyles,
  SingleSelect,
} from '../../../infrastructure/interface/forms/SingleSelect';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { fontWeightBold } from '../../../styling/design/fonts';
import { spacing16 } from '../../../styling/design/spacing';
import { AuthenticationContext } from '../../authentication/AuthenticationContext';
import { DashboardComponentApiRequestStateWrapper } from '../DashboardComponentApiRequestStateWrapper';
import { DashboardComponentLayout } from '../DashboardComponentLayout';
import { allCounterpartsCounterpartId } from '../../metadata/idConstants';
import { useDashboardFilters } from '../DashboardFiltersContext';
import { ComponentResponse } from '../DashboardComponent';

export const investorPerformanceGraphComponentName = 'Investor Performance Graph';

export const InvestorPerformanceGraph = () => {
  const { translate } = useInternationalisation();
  const [method, setMethod] = useState<InvestorPerformanceGraphMethod>('G');
  const [timeframe, setTimeframe] = useState<InvestorPerformanceGraphTimeframe>('L');
  const { investorId, fromDate } = useDashboardFilters();
  const { getUser } = useContext(AuthenticationContext);
  const userRole = getUser().role;

  const getRequest = useGetJson<
    GetDataForInvestorPerformanceGraphQuery,
    GetDataForInvestorPerformanceGraphResponse
  >('api/dashboards/GetDataForInvestorPerformanceGraph');

  const makeRequest = () => {
    const requestParametersAreValid =
      userRole === 'Investor'
        ? fromDate != null && investorId === null
        : fromDate != null && investorId !== null && investorId !== allCounterpartsCounterpartId;

    if (requestParametersAreValid) {
      getRequest.makeRequest({
        queryParameters: {
          investorId,
          runDate: fromDate!,
          method,
          timeframe,
        },
      });
    }
  };

  useEffect(() => {
    makeRequest();
  }, [investorId, method, timeframe, fromDate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (investorId === allCounterpartsCounterpartId) {
    return (
      <UserMessageContainer>
        {translate(
          'pages.dashboard.components.investorPerformanceGraph.notSuitableForAllInvestors'
        )}
      </UserMessageContainer>
    );
  }

  return (
    <DashboardComponentApiRequestStateWrapper
      apiRequestState={getRequest.state}
      retry={makeRequest}
    >
      {(response, showLoadingOverlay) => (
        <InvestorPerformanceGraphComponent
          response={response}
          showLoadingOverlay={showLoadingOverlay}
          method={method}
          setMethod={setMethod}
          timeframe={timeframe}
          setTimeframe={setTimeframe}
        />
      )}
    </DashboardComponentApiRequestStateWrapper>
  );
};

type ComponentProps = {
  response: GetDataForInvestorPerformanceGraphResponse;
  showLoadingOverlay: boolean;
  method: InvestorPerformanceGraphMethod;
  setMethod: (value: InvestorPerformanceGraphMethod) => void;
  timeframe: InvestorPerformanceGraphTimeframe;
  setTimeframe: (value: InvestorPerformanceGraphTimeframe) => void;
};

const InvestorPerformanceGraphComponent = (props: ComponentProps) => {
  const { translate, formatDate, formatNumber } = useInternationalisation();
  const { data, asOfDate, currencyCode, noDataMessage } = props.response;

  const graphTitle =
    asOfDate == null
      ? translate('pages.dashboard.components.investorPerformanceGraph.noDataTitle')
      : translate('pages.dashboard.components.investorPerformanceGraph.title', {
          asOfDate: formatDate(asOfDate, { dateStyle: 'medium' }),
        });

  const investorCurrencyPrefix = isNullUndefinedOrBlank(currencyCode) ? '' : `${currencyCode} - `;

  const exportFileName = translate(
    'pages.dashboard.components.investorPerformanceGraph.exportName',
    { asOfDate }
  );

  const chartData: Array<ChartDataPoint> = data.map((dataPoint) => ({
    label: dataPoint.fundName,
    value: dataPoint.investorPerformance,
  }));

  return (
    <DashboardComponentLayout
      headerText={investorCurrencyPrefix + graphTitle}
      showLoadingOverlay={props.showLoadingOverlay}
      showNoDataMessage={noDataMessage}
      filterSet={
        data.length === 0 ? (<FilterSetContainer/>):(
        <FilterSetContainer>
          <MethodSelect value={props.method} onChange={props.setMethod} />
          <TimeframeSelect value={props.timeframe} onChange={props.setTimeframe} />
        </FilterSetContainer>)
      }
      headerOrientation="column"
    >
      {data.length === 0 ? (
        <UserMessageContainer>
          {translate('pages.dashboard.components.investorPerformanceGraph.noDataMessage')}
        </UserMessageContainer>
      ) : (
        <BarChart
          dataPoints={chartData}
          exportFilename={exportFileName}
          height="stretch"
          yAxisOptions={{
            labels: {
              formatter() {
                return `${formatNumber(this.value, { decimalPlaces: 3 })}%`;
              },
            },
          }}
          tooltipOptions={{
            pointFormatter() {
              const formattedInvestorPerformance =
                this.y === undefined ? '' : formatNumber(this.y, { decimalPlaces: 6 });
              return `<span style="color:${this.color}">●</span><b>${formattedInvestorPerformance}%</b>`;
            },
          }}
        />
      )}
    </DashboardComponentLayout>
  );
};

type MethodSelectProps = {
  value: InvestorPerformanceGraphMethod;
  onChange: (value: InvestorPerformanceGraphMethod) => void;
};

const MethodSelect = (props: MethodSelectProps) => {
  const { translate } = useInternationalisation();

  const translateOptionLabel = useCallback(
    (key: string) => translate(`pages.dashboard.components.investorPerformanceGraph.method.${key}`),
    [translate]
  );

  const options: SelectOptions<InvestorPerformanceGraphMethod> = useMemo(
    () => [
      { label: translateOptionLabel('geometric'), value: 'G' },
      { label: translateOptionLabel('irr'), value: 'I' },
    ],
    [translateOptionLabel]
  );

  return (
    <div>
      <FieldLabel>
        {translate('pages.dashboard.components.investorPerformanceGraph.method.fieldLabel')}
      </FieldLabel>
      <SingleSelect<InvestorPerformanceGraphMethod>
        options={options}
        value={props.value}
        onChange={(value) => props.onChange(value!)}
      />
    </div>
  );
};

type TimeframeSelectProps = {
  value: InvestorPerformanceGraphTimeframe;
  onChange: (value: InvestorPerformanceGraphTimeframe) => void;
};

const TimeframeSelect = (props: TimeframeSelectProps) => {
  const { translate } = useInternationalisation();

  const translateOptionLabel = useCallback(
    (key: string) =>
      translate(`pages.dashboard.components.investorPerformanceGraph.timeframe.${key}`),
    [translate]
  );

  const options: SelectOptions<InvestorPerformanceGraphTimeframe> = useMemo(
    () => [
      { label: translateOptionLabel('lastValuation'), value: 'L' },
      { label: translateOptionLabel('monthToDate'), value: 'M' },
      { label: translateOptionLabel('quarterToDate'), value: 'Q' },
      { label: translateOptionLabel('yearToDate'), value: 'Y' },
      { label: translateOptionLabel('oneYear'), value: '1' },
      { label: translateOptionLabel('threeYear'), value: '3' },
      { label: translateOptionLabel('fiveYear'), value: '5' },
      { label: translateOptionLabel('tenYear'), value: '10' },
      { label: translateOptionLabel('inceptionToDate'), value: 'I' },
    ],
    [translateOptionLabel]
  );

  return (
    <div>
      <FieldLabel>
        {translate('pages.dashboard.components.investorPerformanceGraph.timeframe.fieldLabel')}
      </FieldLabel>
      <SingleSelect<InvestorPerformanceGraphTimeframe>
        options={options}
        value={props.value}
        onChange={(value) => props.onChange(value!)}
        customStyles={getCustomSingleSelectStyles({ menuList: () => ({ maxHeight: '200px' }) })}
      />
    </div>
  );
};

const FilterSetContainer = styled.div`
  display: flex;
  gap: ${spacing16};
`;

const UserMessageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: ${fontWeightBold};
`;

type InvestorPerformanceGraphMethod = 'G' | 'I';

type InvestorPerformanceGraphTimeframe = 'L' | 'M' | 'Q' | 'Y' | '1' | '2' | '3' | '5' | '10' | 'I';

type GetDataForInvestorPerformanceGraphQuery =
  | NonInvestorGetDataForInvestorPerformanceGraphQuery
  | InvestorGetDataForInvestorPerformanceGraphQuery;

type NonInvestorGetDataForInvestorPerformanceGraphQuery = {
  investorId: number;
  runDate: IsoDatestamp;
  method: InvestorPerformanceGraphMethod;
  timeframe: InvestorPerformanceGraphTimeframe;
};

type InvestorGetDataForInvestorPerformanceGraphQuery = {
  investorId: null;
  runDate: IsoDatestamp;
  method: InvestorPerformanceGraphMethod;
  timeframe: InvestorPerformanceGraphTimeframe;
};

type GetDataForInvestorPerformanceGraphResponse = ComponentResponse & {
  currencyCode: string | null;
  asOfDate: IsoDatestamp | null;
  data: Array<{
    fundName: string;
    investorPerformance: number;
  }>;
};
