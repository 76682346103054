import {
  ControlProps,
  DropdownIndicatorProps,
  GroupHeadingProps,
  GroupProps,
  InputProps,
  MenuProps,
  OptionProps,
  SingleValueProps,
  StylesConfig,
  ValueContainerProps,
} from 'react-select';
import { backgroundColours, borderColours, textColours } from '../../../styling/design/colours';
import { spacing16, spacing4, spacing8 } from '../../../styling/design/spacing';
import {
  ClearIndicatorProps,
  IndicatorSeparatorProps,
  LoadingIndicatorProps,
} from 'react-select/dist/declarations/src/components/indicators';
import { CSSObject } from '@emotion/react';
import {
  ContainerProps,
  IndicatorsContainerProps,
} from 'react-select/dist/declarations/src/components/containers';
import {
  MenuListProps,
  NoticeProps,
  PortalStyleArgs,
} from 'react-select/dist/declarations/src/components/Menu';
import { MultiValueProps } from 'react-select/dist/declarations/src/components/MultiValue';
import { PlaceholderProps } from 'react-select/dist/declarations/src/components/Placeholder';
import { GroupedSelectOption, SelectOption } from './BaseSelect';
import { inputHeightPixels } from './Input';

export type BaseSelectStylesConfig<TValue extends unknown, IsMulti extends boolean> = StylesConfig<
  SelectOption<TValue>,
  IsMulti
>;

export type BaseSelectStylesConfigFactory<TValue, IsMulti extends boolean> = (
  isMulti: IsMulti,
  highlight?: boolean
) => BaseSelectStylesConfig<TValue, IsMulti>;

export const defaultSelectBorderWidthAndType = '1px solid';
export const defaultSelectBorder = `${defaultSelectBorderWidthAndType} ${borderColours.default}`;
export const defaultSelectDisabledBorder = `${defaultSelectBorderWidthAndType} ${borderColours.disabled}`;
export const defaultSelectHighlightBorder = `${defaultSelectBorderWidthAndType} ${borderColours.highlight}`;
export const defaultSelectFocusBorder = `${defaultSelectBorderWidthAndType} ${borderColours.focus}`;

export const defaultSelectOptionBorderWidthAndType = '1px solid';
export const defaultSelectOptionBorder = `${defaultSelectOptionBorderWidthAndType} transparent`;
export const defaultSelectOptionFocusBorder = `${defaultSelectOptionBorderWidthAndType} ${borderColours.default}`;

export const baseSelectHorizontalPadding = spacing8;
export const baseSelectVerticalPadding = '3px';

export const baseSelectStyles = <TValue extends unknown, IsMulti extends boolean>(
  isMulti: IsMulti,
  highlight?: boolean
): BaseSelectStylesConfig<TValue, IsMulti> => ({
  control: (provided, state) => {
    const disabledStyles = state.isDisabled
      ? {
          border: defaultSelectDisabledBorder,
          backgroundColor: backgroundColours.disabled,
          cursor: 'not-allowed',
        }
      : {};

    const highlightStyles = highlight
      ? {
          border: defaultSelectHighlightBorder,
        }
      : {};

    const focusStyles = state.isFocused
      ? {
          border: defaultSelectFocusBorder,
        }
      : {};

    return {
      ...provided,
      height: isMulti ? `` : `${inputHeightPixels}px`, //do not set height for multi select to auto-grow
      cursor: 'pointer',
      pointerEvents: 'auto',
      boxShadow: 'none',
      border: defaultSelectBorder,
      borderRadius: '5px',
      padding: `${baseSelectVerticalPadding} ${baseSelectHorizontalPadding}`,
      '&:hover': {}, // prevent default hover styles
      ...disabledStyles,
      ...highlightStyles,
      ...focusStyles,
    };
  },
  option: (provided, state) => {
    const focusStyles = state.isFocused
      ? {
          color: textColours.default,
          backgroundColor: backgroundColours.defaultHover,
          borderTop: defaultSelectOptionFocusBorder,
          borderBottom: defaultSelectOptionFocusBorder,
        }
      : {};

    const selectedStyles = state.isSelected
      ? {
          color: textColours.selected,
          backgroundColor: backgroundColours.selected,
          borderColor: borderColours.selected,
        }
      : {};

    return {
      ...provided,
      border: defaultSelectOptionBorder,
      padding: `${spacing4} ${spacing16}`,
      display: 'flex',
      alignItems: 'center',
      minHeight: '35px',
      ...focusStyles,
      ...selectedStyles,
    };
  },
  group: (provided, state) => {
    return {
      ...provided,
      margin: '0',
      padding: `${spacing4} 0 0 0`,
    };
  },
  groupHeading: (provided, state) => {
    return {
      ...provided,
      textTransform: 'none',
    };
  },
  dropdownIndicator: (provided, state) => {
    const disabledStyles = state.isDisabled
      ? {
          '&:hover': {},
        }
      : {};

    return {
      ...provided,
      ...disabledStyles,
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      width: 'max-content',
      maxWidth: '90vw',
    };
  },
});

// Changes here should also be handled in applyCustomStylesToDefaults below
export type BaseSelectCustomStyleOptions<TValue extends unknown, IsMulti extends boolean> = {
  clearIndicator?: (
    state: ClearIndicatorProps<SelectOption<TValue>, IsMulti, GroupedSelectOption<TValue>>
  ) => CSSObject;
  container?: (
    state: ContainerProps<SelectOption<TValue>, IsMulti, GroupedSelectOption<TValue>>
  ) => CSSObject;
  control?: (
    state: ControlProps<SelectOption<TValue>, IsMulti, GroupedSelectOption<TValue>>,
    highlight?: boolean
  ) => CSSObject;
  dropdownIndicator?: (
    state: DropdownIndicatorProps<SelectOption<TValue>, IsMulti, GroupedSelectOption<TValue>>
  ) => CSSObject;
  group?: (
    state: GroupProps<SelectOption<TValue>, IsMulti, GroupedSelectOption<TValue>>
  ) => CSSObject;
  groupHeading?: (
    state: GroupHeadingProps<SelectOption<TValue>, IsMulti, GroupedSelectOption<TValue>>
  ) => CSSObject;
  indicatorsContainer?: (
    state: IndicatorsContainerProps<SelectOption<TValue>, IsMulti, GroupedSelectOption<TValue>>
  ) => CSSObject;
  indicatorSeparator?: (
    state: IndicatorSeparatorProps<SelectOption<TValue>, IsMulti, GroupedSelectOption<TValue>>
  ) => CSSObject;
  input?: (
    state: InputProps<SelectOption<TValue>, IsMulti, GroupedSelectOption<TValue>>
  ) => CSSObject;
  loadingIndicator?: (
    state: LoadingIndicatorProps<SelectOption<TValue>, IsMulti, GroupedSelectOption<TValue>>
  ) => CSSObject;
  loadingMessage?: (
    state: NoticeProps<SelectOption<TValue>, IsMulti, GroupedSelectOption<TValue>>
  ) => CSSObject;
  menu?: (
    state: MenuProps<SelectOption<TValue>, IsMulti, GroupedSelectOption<TValue>>
  ) => CSSObject;
  menuList?: (
    state: MenuListProps<SelectOption<TValue>, IsMulti, GroupedSelectOption<TValue>>
  ) => CSSObject;
  menuPortal?: (state: PortalStyleArgs) => CSSObject;
  multiValue?: (
    state: MultiValueProps<SelectOption<TValue>, IsMulti, GroupedSelectOption<TValue>>
  ) => CSSObject;
  multiValueLabel?: (
    state: MultiValueProps<SelectOption<TValue>, IsMulti, GroupedSelectOption<TValue>>
  ) => CSSObject;
  multiValueRemove?: (
    state: MultiValueProps<SelectOption<TValue>, IsMulti, GroupedSelectOption<TValue>>
  ) => CSSObject;
  noOptionsMessage?: (
    state: NoticeProps<SelectOption<TValue>, IsMulti, GroupedSelectOption<TValue>>
  ) => CSSObject;
  option?: (
    state: OptionProps<SelectOption<TValue>, IsMulti, GroupedSelectOption<TValue>>
  ) => CSSObject;
  placeholder?: (
    state: PlaceholderProps<SelectOption<TValue>, IsMulti, GroupedSelectOption<TValue>>
  ) => CSSObject;
  singleValue?: (
    state: SingleValueProps<SelectOption<TValue>, IsMulti, GroupedSelectOption<TValue>>
  ) => CSSObject;
  valueContainer?: (
    state: ValueContainerProps<SelectOption<TValue>, IsMulti, GroupedSelectOption<TValue>>
  ) => CSSObject;
};

export const applyCustomStylesToDefaults = <TValue extends unknown, IsMulti extends boolean>(
  {
    clearIndicator,
    container,
    control,
    dropdownIndicator,
    group,
    groupHeading,
    indicatorsContainer,
    indicatorSeparator,
    input,
    loadingIndicator,
    loadingMessage,
    menu,
    menuList,
    menuPortal,
    multiValue,
    multiValueLabel,
    multiValueRemove,
    noOptionsMessage,
    option,
    placeholder,
    singleValue,
    valueContainer,
  }: BaseSelectCustomStyleOptions<TValue, IsMulti>,
  defaultStyles: BaseSelectStylesConfig<TValue, IsMulti>,
  highlight?: boolean
): BaseSelectStylesConfig<TValue, IsMulti> => ({
  clearIndicator: (provided, state) => ({
    ...(defaultStyles.clearIndicator != null
      ? defaultStyles.clearIndicator(provided, state)
      : provided),
    ...(clearIndicator != null ? clearIndicator(state) : {}),
  }),
  container: (provided, state) => ({
    ...(defaultStyles.container != null ? defaultStyles.container(provided, state) : provided),
    ...(container != null ? container(state) : {}),
  }),
  control: (provided, state) => ({
    ...(defaultStyles.control != null ? defaultStyles.control(provided, state) : provided),
    ...(control != null ? control(state, highlight) : {}),
  }),
  dropdownIndicator: (provided, state) => ({
    ...(defaultStyles.dropdownIndicator != null
      ? defaultStyles.dropdownIndicator(provided, state)
      : provided),
    ...(dropdownIndicator != null ? dropdownIndicator(state) : {}),
  }),
  group: (provided, state) => ({
    ...(defaultStyles.group != null ? defaultStyles.group(provided, state) : provided),
    ...(group != null ? group(state) : {}),
  }),
  groupHeading: (provided, state) => ({
    ...(defaultStyles.groupHeading != null
      ? defaultStyles.groupHeading(provided, state)
      : provided),
    ...(groupHeading != null ? groupHeading(state) : {}),
  }),
  indicatorsContainer: (provided, state) => ({
    ...(defaultStyles.indicatorsContainer != null
      ? defaultStyles.indicatorsContainer(provided, state)
      : provided),
    ...(indicatorsContainer != null ? indicatorsContainer(state) : {}),
  }),
  indicatorSeparator: (provided, state) => ({
    ...(defaultStyles.indicatorSeparator != null
      ? defaultStyles.indicatorSeparator(provided, state)
      : provided),
    ...(indicatorSeparator != null ? indicatorSeparator(state) : {}),
  }),
  input: (provided, state) => ({
    ...(defaultStyles.input != null ? defaultStyles.input(provided, state) : provided),
    ...(input != null ? input(state) : {}),
  }),
  loadingIndicator: (provided, state) => ({
    ...(defaultStyles.loadingIndicator != null
      ? defaultStyles.loadingIndicator(provided, state)
      : provided),
    ...(loadingIndicator != null ? loadingIndicator(state) : {}),
  }),
  loadingMessage: (provided, state) => ({
    ...(defaultStyles.loadingMessage != null
      ? defaultStyles.loadingMessage(provided, state)
      : provided),
    ...(loadingMessage != null ? loadingMessage(state) : {}),
  }),
  menu: (provided, state) => ({
    ...(defaultStyles.menu != null ? defaultStyles.menu(provided, state) : provided),
    ...(menu != null ? menu(state) : {}),
  }),
  menuList: (provided, state) => ({
    ...(defaultStyles.menuList != null ? defaultStyles.menuList(provided, state) : provided),
    ...(menuList != null ? menuList(state) : {}),
  }),
  menuPortal: (provided, state) => ({
    ...(defaultStyles.menuPortal != null ? defaultStyles.menuPortal(provided, state) : provided),
    ...(menuPortal != null ? menuPortal(state) : {}),
  }),
  multiValue: (provided, state) => ({
    ...(defaultStyles.multiValue != null ? defaultStyles.multiValue(provided, state) : provided),
    ...(multiValue != null ? multiValue(state) : {}),
  }),
  multiValueLabel: (provided, state) => ({
    ...(defaultStyles.multiValueLabel != null
      ? defaultStyles.multiValueLabel(provided, state)
      : provided),
    ...(multiValueLabel != null ? multiValueLabel(state) : {}),
  }),
  multiValueRemove: (provided, state) => ({
    ...(defaultStyles.multiValueRemove != null
      ? defaultStyles.multiValueRemove(provided, state)
      : provided),
    ...(multiValueRemove != null ? multiValueRemove(state) : {}),
  }),
  option: (provided, state) => ({
    ...(defaultStyles.option != null ? defaultStyles.option(provided, state) : provided),
    ...(option != null ? option(state) : {}),
  }),
  placeholder: (provided, state) => ({
    ...(defaultStyles.placeholder != null ? defaultStyles.placeholder(provided, state) : provided),
    ...(placeholder != null ? placeholder(state) : {}),
  }),
  singleValue: (provided, state) => ({
    ...(defaultStyles.singleValue != null ? defaultStyles.singleValue(provided, state) : provided),
    ...(singleValue != null ? singleValue(state) : {}),
  }),
  valueContainer: (provided, state) => ({
    ...(defaultStyles.valueContainer != null
      ? defaultStyles.valueContainer(provided, state)
      : provided),
    ...(valueContainer != null ? valueContainer(state) : {}),
  }),
});

export const getCustomBaseSelectStyles =
  <TValue extends unknown, IsMulti extends boolean>(
    customStyles: BaseSelectCustomStyleOptions<TValue, IsMulti>
  ): BaseSelectStylesConfigFactory<TValue, IsMulti> =>
  (isMulti: IsMulti, highlight?: boolean): BaseSelectStylesConfig<TValue, IsMulti> =>
    applyCustomStylesToDefaults(
      customStyles,
      baseSelectStyles<TValue, IsMulti>(isMulti, highlight),
      highlight
    );
