import React, { useEffect, useState } from 'react';
import { components, OnChangeValue, OptionProps } from 'react-select';
import {
  BaseSelect,
  BaseSelectProps,
  GroupedSelectOptions,
  isGroupedOptions,
  SelectOption,
  SelectOptions,
  BaseSelectComponentsConfig,
  GroupedSelectOption,
} from './BaseSelect';
import {
  applyCustomStylesToDefaults,
  BaseSelectCustomStyleOptions,
  BaseSelectStylesConfig,
  BaseSelectStylesConfigFactory,
  getCustomBaseSelectStyles,
} from './baseSelectStyles';
import { Checkbox } from './Checkbox';
import styled from 'styled-components/macro';
import { spacing16, spacing4, spacing8 } from '../../../styling/design/spacing';
import { borderColours, colourGrey01, textColours } from '../../../styling/design/colours';

export const multiSelectTestId = 'multi-select';

export type MultiSelectProps<TValue extends unknown> = BaseSelectProps<TValue, true> & {
  values: Array<TValue>;
  onChange: (newValues: Array<TValue>) => void;
};

export const MultiSelect = <TValue extends unknown>(props: MultiSelectProps<TValue>) => {
  const [selectedOptions, setSelectedOptions] = useState<Array<SelectOption<TValue>>>(() =>
    getOptionsFromValues(props.values, props.options)
  );

  useEffect(() => {
    setSelectedOptions(getOptionsFromValues(props.values, props.options));
  }, [props.values, props.options]);

  const onChange = (newOptions: OnChangeValue<SelectOption<TValue>, true>) => {
    props.onChange(newOptions.map((option) => option.value));
  };

  return (
    <BaseSelect
      {...props}
      data-testid={props['data-testid'] ?? multiSelectTestId}
      customStyles={props.customStyles ?? defaultMultiSelectStyles<TValue>()}
      customComponents={getComponents<TValue>()}
      isMulti={true}
      selectedOption={selectedOptions}
      onChange={onChange}
    />
  );
};

const getOptionsFromValues = <TValue extends unknown>(
  values: Array<TValue>,
  options: SelectOptions<TValue> | GroupedSelectOptions<TValue>
): Array<SelectOption<TValue>> => {
  if (isGroupedOptions(options)) {
    const groupedOptions = options as GroupedSelectOptions<TValue>;
    const allOptionsFromGroups: SelectOptions<TValue> = groupedOptions.flatMap(
      (group) => group.options
    );

    return allOptionsFromGroups.filter((option) => values.includes(option.value));
  }

  return options.filter((option) => values.includes(option.value));
};

export type MultiSelectComponentsConfig<TValue extends unknown> = BaseSelectComponentsConfig<
  TValue,
  true
>;

const getComponents = <TValue extends unknown>(): MultiSelectComponentsConfig<TValue> => {
  const Option = (props: OptionProps<SelectOption<TValue>, true, GroupedSelectOption<TValue>>) => (
    <components.Option {...props}>
      <OptionCheckbox checked={props.isSelected} />
      {props.label}
    </components.Option>
  );

  return { Option };
};

const OptionCheckbox = styled(Checkbox)`
  margin-right: ${spacing16};
`;

export type MultiSelectCustomStyleOptions<TValue extends unknown> = BaseSelectCustomStyleOptions<
  TValue,
  true
>;

export type MultiSelectStylesConfig<TValue extends unknown> = BaseSelectStylesConfig<TValue, true>;

export type MultiSelectStylesConfigFactory<TValue> = BaseSelectStylesConfigFactory<TValue, true>;

const defaultMultiSelectStyles = <TValue extends unknown>() =>
  getCustomBaseSelectStyles<TValue, true>({
    multiValue: (state) => ({
      backgroundColor: 'transparent',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 0,
      marginRight: spacing16,
    }),
    multiValueLabel: (state) => {
      const disabledStyles = state.isDisabled
        ? {
            color: textColours.disabled,
          }
        : {};

      return {
        paddingLeft: 0,
        paddingRight: spacing8,
        ...disabledStyles,
      };
    },
    multiValueRemove: (state) => {
      const disabledStyles = state.isDisabled
        ? {
            color: textColours.disabled,
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }
        : {};

      return {
        backgroundColor: colourGrey01,
        height: spacing16,
        width: spacing16,
        borderRadius: '50%',
        padding: spacing4,
        transition: 'color 0.25s ease, background-color 0.25s ease',
        ...disabledStyles,
      };
    },
    option: (state) => {
      const selectedStyles = state.isSelected
        ? {
            color: textColours.default,
            backgroundColor: colourGrey01,
            borderColor: borderColours.default,
          }
        : {};

      return {
        ...selectedStyles,
      };
    },
  });

export const getCustomMultiSelectStyles =
  <TValue extends unknown>(
    customStyles: MultiSelectCustomStyleOptions<TValue>
  ): MultiSelectStylesConfigFactory<TValue> =>
  (highlight?: boolean): MultiSelectStylesConfig<TValue> =>
    applyCustomStylesToDefaults(
      customStyles,
      defaultMultiSelectStyles<TValue>()(true, highlight),
      highlight
    );
